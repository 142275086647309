export const interfaces = {
  legacyRegistrar: '0x7ba18ba1',
  permanentRegistrar: '0x018fac06',
  permanentRegistrarWithConfig: '0xca27ac4c',
  baseRegistrar: '0x6ccb2df4',
  dnsRegistrar: '0x1aa2e641',
  bulkRenewal: '0x3150bfba',
  dnssecClaimOld: '0x1aa2e641',
  dnssecClaimNew: '0x17d8f49b'
}
