import React from 'react'

export default function SpaceIDTextIcon({
  className = 'text-green-100',
  size = 299,
}) {
  return (
    <div className={className}>
      <svg
        width={size}
        viewBox="0 0 299 43"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M30.0089 36.314C31.2698 34.298 31.9164 31.9611 31.8703 29.5867C31.8703 27.3909 31.3827 25.4949 30.419 23.9444C29.376 22.3109 27.9598 20.946 26.2861 19.9611C24.1049 18.6883 21.7984 17.6415 19.4027 16.8373C19.2248 16.7802 19.0671 16.7231 18.9323 16.6717L18.5795 16.546C17.0078 16.0121 15.8319 15.5809 15.0747 15.264C14.3824 14.9946 13.742 14.6082 13.1818 14.1218C12.9366 13.9084 12.7405 13.6449 12.6069 13.3493C12.4733 13.0536 12.4052 12.7328 12.4074 12.4086C12.3924 11.9266 12.4944 11.4482 12.7046 11.0137C12.9148 10.5793 13.227 10.2017 13.6148 9.91293C14.3806 9.32472 15.4791 9.0249 16.8759 9.0249C18.5985 9.04025 20.2995 9.40965 21.8721 10.11C23.2387 10.6928 24.5114 11.4731 25.6494 12.4257L31.1418 5.76118C29.2365 4.06977 27.0588 2.70969 24.7 1.73791C22.0652 0.702811 19.2521 0.191585 16.4198 0.233116C13.2649 0.233116 10.6119 0.787065 8.54692 1.88068C6.48189 2.9743 4.9704 4.41628 4.0182 6.18378C3.05118 7.98569 2.55322 10.0002 2.56981 12.0431C2.56981 15.6352 3.71705 18.3906 5.9599 20.2381C8.44538 22.223 11.3074 23.6885 14.3749 24.5469C15.952 24.9843 17.4953 25.535 18.9925 26.1944C20.1053 26.7227 20.8482 27.2509 21.2612 27.8049C21.6865 28.3841 21.9092 29.0863 21.895 29.8037C21.907 30.3363 21.7814 30.863 21.5303 31.3335C21.2792 31.804 20.911 32.2024 20.461 32.4906C19.5461 33.1188 18.2325 33.4243 16.4457 33.4243C12.8806 33.4243 9.43029 31.9024 6.18935 28.8985L0 35.3032C2.11929 37.5114 4.65271 39.2847 7.45704 40.5229C10.3805 41.7865 13.5368 42.4284 16.7239 42.4074C19.7526 42.4074 22.4601 41.8706 24.7344 40.8084C26.8899 39.8461 28.7211 38.2857 30.0089 36.314Z"
          fill="#1EEFA4"
        />
        <path
          d="M162.435 32.0285L157.903 28.4136C156.428 30.3542 154.383 31.7891 152.052 32.5189C149.72 33.2487 147.218 33.237 144.893 32.4855C142.569 31.734 140.537 30.2801 139.081 28.3258C137.625 26.3716 136.816 24.0142 136.768 21.5813C136.72 19.1485 137.434 16.7612 138.812 14.7513C140.19 12.7415 142.162 11.209 144.455 10.3669C146.749 9.5248 149.248 9.41499 151.607 10.0527C153.966 10.6905 156.066 12.0441 157.616 13.9253L164.99 8.04601C162.214 4.64077 158.443 2.17972 154.199 1.00413C149.956 -0.17147 145.45 -0.00336228 141.306 1.48514C137.163 2.97365 133.587 5.70879 131.075 9.31131C128.563 12.9138 127.239 17.2052 127.287 21.5904C127.335 25.9756 128.752 30.2373 131.342 33.7847C133.932 37.332 137.567 39.9891 141.742 41.3876C145.917 42.7861 150.425 42.8566 154.643 41.5895C158.86 40.3223 162.576 37.7802 165.277 34.3157L162.435 32.0285Z"
          fill="#1EEFA4"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M76.2397 13.7511C76.2397 16.5437 75.6661 19.0308 74.5189 21.1437C73.387 23.2363 71.66 24.9498 69.5542 26.0693C67.3802 27.24 64.7673 27.834 61.7874 27.834H54.6688V40.8974H44.8427V0.230774H61.6497C64.5723 0.230774 67.1651 0.821842 69.3592 1.98399C71.4759 3.08007 73.2378 4.75009 74.4414 6.80106C75.6539 8.91613 76.2749 11.3161 76.2397 13.7511ZM63.7033 18.414C64.5269 17.9782 65.2152 17.3265 65.6937 16.5294C66.1793 15.7161 66.4313 14.7857 66.4222 13.8396C66.4353 12.9381 66.1777 12.0531 65.6823 11.2983C65.1881 10.5615 64.5048 9.96994 63.7033 9.58507C62.843 9.17342 61.8985 8.9662 60.9442 8.97973H54.6688V19.0879H60.9155C61.8867 19.0985 62.8451 18.8668 63.7033 18.414Z"
          fill="#1EEFA4"
        />
        <path
          d="M187.714 24.3475H203.061V16.3096H187.714V8.97973H207.335V0.230774H177.888V40.8974H207.779V32.1485H187.714V24.3475Z"
          fill="currentColor"
        />
        <path
          d="M237.258 0.230774H247.084V40.8974H237.258V0.230774Z"
          fill="currentColor"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M296.154 30.9075C298.019 27.7742 299.003 24.1984 299 20.5561C298.997 16.9138 298.009 13.3393 296.14 10.2086C294.24 7.12671 291.55 4.60374 288.347 2.89881C285.003 1.11201 281.26 0.194678 277.465 0.231861H262.193V40.8985H277.554C281.334 40.9353 285.062 40.0178 288.39 38.2316C291.588 36.5238 294.269 33.9949 296.154 30.9075ZM287.59 26.3017C286.66 27.9651 285.282 29.3368 283.612 30.2621C281.868 31.2223 279.903 31.7143 277.91 31.6898H272.019V9.45482H277.881C279.879 9.42799 281.849 9.92007 283.597 10.8825C285.269 11.8113 286.651 13.1819 287.59 14.843C288.562 16.5886 289.057 18.558 289.024 20.5538C289.057 22.5614 288.562 24.5427 287.59 26.3017Z"
          fill="#1EEFA4"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M95.6453 0.230774H104.404L120.07 40.9545H109.989L107.763 34.4528V34.4328H92.1892V34.4528L89.9664 40.9545H79.8879L95.6453 0.230774ZM99.9962 11.6381L99.8556 12.0521L95.129 25.8666H104.849L100.14 12.0521L99.9962 11.6381Z"
          fill="#1EEFA4"
        />
      </svg>
    </div>
  )
}
