import React from 'react'

export default function TwitterIcon({
  className = 'text-green-200',
  size = 20,
}) {
  return (
    <div className={className}>
      <svg
        width={size}
        viewBox="0 0 20 17"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M6.38252 16.25C13.7414 16.25 17.7656 10.1912 17.7656 4.93713C17.7656 4.76504 17.7621 4.59372 17.7543 4.42319C18.5355 3.86195 19.2145 3.16158 19.75 2.36429C19.0332 2.68096 18.2617 2.89413 17.4525 2.99025C18.2785 2.49801 18.9126 1.71934 19.2118 0.791082C18.4388 1.2465 17.5828 1.57751 16.6713 1.75621C15.9413 0.98338 14.9019 0.5 13.751 0.5C11.5417 0.5 9.75004 2.28063 9.75004 4.4755C9.75004 4.78751 9.78514 5.091 9.85378 5.38211C6.52868 5.21583 3.58024 3.63367 1.60697 1.2279C1.26338 1.81548 1.06526 2.49803 1.06526 3.22632C1.06526 4.60576 1.77157 5.82354 2.84563 6.53596C2.18924 6.51581 1.57265 6.33673 1.03372 6.03868C1.03313 6.05536 1.03313 6.07163 1.03313 6.08945C1.03313 8.01504 2.41218 9.62274 4.2428 9.98705C3.90662 10.0781 3.55292 10.127 3.18785 10.127C2.93046 10.127 2.67968 10.1018 2.43593 10.0553C2.94527 11.6351 4.42222 12.7847 6.17327 12.8168C4.80397 13.8835 3.07903 14.5188 1.20431 14.5188C0.881781 14.5188 0.563154 14.5005 0.25 14.4637C2.02061 15.5916 4.12305 16.2498 6.38273 16.2498"
          fill="currentColor"
        />
      </svg>
    </div>
  )
}
