export const chainsInfo = [
  { chainId: 0, name: 'Kardia', isSupport: false },
  { chainId: 1, name: 'ETHT', isSupport: false },
  { chainId: 2, name: 'Expanse', isSupport: false },
  { chainId: 3, name: 'Ropsten', isSupport: false },
  { chainId: 4, name: 'Rinkeby', isSupport: false },
  { chainId: 8, name: 'Ubiq', isSupport: false },
  { chainId: 10, name: 'optimism', isSupport: false },
  { chainId: 19, name: 'songbird', isSupport: false },
  { chainId: 20, name: 'elastos', isSupport: false },
  { chainId: 25, name: 'Cronos Mainnet Beta', isSupport: false },
  { chainId: 30, name: 'rsk', isSupport: false },
  { chainId: 40, name: 'telos', isSupport: false },
  { chainId: 42, name: 'Kovan', isSupport: false },
  { chainId: 50, name: 'xdc', isSupport: false },
  { chainId: 52, name: 'csc', isSupport: false },
  { chainId: 55, name: 'zyx', isSupport: false },
  {
    chainId: 56,
    name: 'BNB',
    chainName: 'BSC MAINNET',
    rpc: 'https://bsc-dataseed.binance.org',
    isSupport:
      process.env.REACT_APP_MODE === 'production' ||
      process.env.REACT_APP_MODE === 'alpha',
  },
  { chainId: 57, name: 'syscoin', isSupport: false },
  { chainId: 60, name: 'gochain', isSupport: false },
  { chainId: 61, name: 'ethclassic', isSupport: false },
  { chainId: 66, name: 'okexchain', isSupport: false },
  { chainId: 70, name: 'hoo', isSupport: false },
  { chainId: 82, name: 'meter', isSupport: false },
  { chainId: 88, name: 'tomochain', isSupport: false },
  {
    chainId: 97,
    name: 'BNB',
    chainName: 'BSC TESTNET',
    rpc: 'https://bsc-testnet.nodereal.io/v1/c9bc598b84b14e62b11c0a1b74b37cbd',
    isSupport: process.env.REACT_APP_MODE === 'stg',
  },
  { chainId: 100, name: 'xdai', isSupport: false },
  { chainId: 106, name: 'velas', isSupport: false },
  { chainId: 108, name: 'thundercore', isSupport: false },
  { chainId: 122, name: 'fuse', isSupport: false },
  { chainId: 128, name: 'heco', isSupport: false },
  { chainId: 137, name: 'Polygon Mainnet', isSupport: false },
  { chainId: 200, name: 'xdaiarb', isSupport: false },
  { chainId: 246, name: 'energyweb', isSupport: false },
  { chainId: 250, name: 'Fantom Opera', isSupport: false },
  { chainId: 269, name: 'hpb', isSupport: false },
  { chainId: 288, name: 'boba', isSupport: false },
  { chainId: 321, name: 'kucoin', isSupport: false },
  { chainId: 336, name: 'shiden', isSupport: false },
  { chainId: 361, name: 'theta', isSupport: false },
  { chainId: 416, name: 'sx', isSupport: false },
  { chainId: 420, name: 'Goerli', isSupport: false },
  { chainId: 534, name: 'candle', isSupport: false },
  { chainId: 592, name: 'astar', isSupport: false },
  { chainId: 820, name: 'callisto', isSupport: false },
  { chainId: 888, name: 'wanchain', isSupport: false },
  { chainId: 1088, name: 'metis', isSupport: false },
  { chainId: 1246, name: 'omchain', isSupport: false },
  { chainId: 1284, name: 'moonbeam', isSupport: false },
  { chainId: 1285, name: 'moonriver', isSupport: false },
  { chainId: 2020, name: 'ronin', isSupport: false },
  { chainId: 2222, name: 'kava', isSupport: false },
  { chainId: 2612, name: 'ezchain', isSupport: false },
  { chainId: 4181, name: 'phi', isSupport: false },
  { chainId: 4689, name: 'iotex', isSupport: false },
  { chainId: 5050, name: 'xlc', isSupport: false },
  { chainId: 5551, name: 'nahmii', isSupport: false },
  { chainId: 7777, name: 'nmactest', isSupport: false },
  { chainId: 8217, name: 'klaytn', isSupport: false },
  { chainId: 9001, name: 'evmos', isSupport: false },
  { chainId: 10000, name: 'smartbch', isSupport: false },
  { chainId: 103090, name: 'crystaleum', isSupport: false },
  { chainId: 32659, name: 'fusion', isSupport: false },
  { chainId: 42161, name: 'arbitrum', isSupport: false },
  { chainId: 42220, name: 'celo', isSupport: false },
  { chainId: 42262, name: 'oasis', isSupport: false },
  { chainId: 43114, name: 'avalanche', isSupport: false },
  { chainId: 71402, name: 'godwoken', isSupport: false },
  { chainId: 200625, name: 'akroma', isSupport: false },
  { chainId: 333999, name: 'polis', isSupport: false },
  { chainId: 1313161554, name: 'aurora', isSupport: false },
  { chainId: 1666600000, name: 'harmony', isSupport: false },
  { chainId: 11297108109, name: 'palm', isSupport: false },
  { chainId: 836542336838601, name: 'curio', isSupport: false },
]
