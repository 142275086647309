import React from 'react'

export default function BarIcon({ className = 'text-[#CCFCFF]', size = 22 }) {
  return (
    <div className={className}>
      <svg
        width={size}
        viewBox="0 0 22 14"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M1.66667 14H6.33333C6.975 14 7.5 13.475 7.5 12.8333C7.5 12.1917 6.975 11.6667 6.33333 11.6667H1.66667C1.025 11.6667 0.5 12.1917 0.5 12.8333C0.5 13.475 1.025 14 1.66667 14ZM0.5 1.16667C0.5 1.80833 1.025 2.33333 1.66667 2.33333H20.3333C20.975 2.33333 21.5 1.80833 21.5 1.16667C21.5 0.525 20.975 0 20.3333 0H1.66667C1.025 0 0.5 0.525 0.5 1.16667ZM1.66667 8.16667H13.3333C13.975 8.16667 14.5 7.64167 14.5 7C14.5 6.35833 13.975 5.83333 13.3333 5.83333H1.66667C1.025 5.83333 0.5 6.35833 0.5 7C0.5 7.64167 1.025 8.16667 1.66667 8.16667Z"
          fill="currentColor"
          fillOpacity="0.6"
        />
      </svg>
    </div>
  )
}
