import React from 'react'

export default function CloseIcon({ className, size = 11 }) {
  return (
    <svg
      className={className}
      width={size}
      viewBox="0 0 11 11"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <line
        x1="0.5"
        y1="-0.5"
        x2="13.6421"
        y2="-0.5"
        transform="matrix(-0.707107 0.707106 -0.707107 -0.707106 10 0)"
        stroke="white"
        strokeLinecap="round"
      />
      <line
        x1="0.5"
        y1="-0.5"
        x2="13.6421"
        y2="-0.5"
        transform="matrix(-0.707107 0.707106 -0.707107 -0.707106 10 0)"
        stroke="white"
        strokeLinecap="round"
      />
      <line
        x1="0.5"
        y1="-0.5"
        x2="13.6421"
        y2="-0.5"
        transform="matrix(-0.707107 0.707106 -0.707107 -0.707106 10 0)"
        stroke="white"
        strokeLinecap="round"
      />
      <line
        x1="0.5"
        y1="-0.5"
        x2="13.6421"
        y2="-0.5"
        transform="matrix(-0.707107 0.707106 -0.707107 -0.707106 10 0)"
        stroke="white"
        strokeLinecap="round"
      />
      <line
        x1="0.5"
        y1="-0.5"
        x2="13.6421"
        y2="-0.5"
        transform="matrix(-0.707107 -0.707106 0.707107 -0.707106 11 10)"
        stroke="white"
        strokeLinecap="round"
      />
      <line
        x1="0.5"
        y1="-0.5"
        x2="13.6421"
        y2="-0.5"
        transform="matrix(-0.707107 -0.707106 0.707107 -0.707106 11 10)"
        stroke="white"
        strokeLinecap="round"
      />
      <line
        x1="0.5"
        y1="-0.5"
        x2="13.6421"
        y2="-0.5"
        transform="matrix(-0.707107 -0.707106 0.707107 -0.707106 11 10)"
        stroke="white"
        strokeLinecap="round"
      />
      <line
        x1="0.5"
        y1="-0.5"
        x2="13.6421"
        y2="-0.5"
        transform="matrix(-0.707107 -0.707106 0.707107 -0.707106 11 10)"
        stroke="white"
        strokeLinecap="round"
      />
    </svg>
  )
}
